export default {
    traits: [
        [
            {
                trait: 'pewny siebie',
                description: 'polegający na sobie, zdecydowany, niezależny',
                color: 'red',
                score: 0
            },
            {
                trait: 'zdyscyplinowany',
                description: 'systematyczny, sprawny, opanowany',
                color: 'blue',
                score: 0
            },
            {
                trait: 'odpowiedzialny',
                description: 'godny zaufania, można na nim polegać, punktualny',
                color: 'green',
                score: 0
            },
            {
                trait: 'ekspresyjny',
                description: 'okazujący uczucia, wyrażający się otwarcie, gestykulujący',
                color: 'yellow',
                score: 0
            }
        ],
        [
            {
                trait: 'niezorganizowany',
                description: 'bezładny, nieuporządkowany, chaotyczny',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'nieśmiały',
                description: 'wrażliwy, spokojny, łatwo go zakłopotać',
                color: 'green',
                score: 0
            },
            {
                trait: 'nieelastyczny',
                description: 'nieugięty, stały, czepia się drobiazgów',
                color: 'blue',
                score: 0
            },
            {
                trait: 'dominujący',
                description: 'lubi rozkazywać, narzucający zdanie, nie refleksyjny',
                color: 'red',
                score: 0
            }
        ],
        [
            {
                trait: 'porządny',
                description: 'staranny, dba o porządek, zorganizowany',
                color: 'blue',
                score: 0
            },
            {
                trait: 'wspierający innych',
                description: 'pomocny, zachęca innych, wyrozumiały',
                color: 'green',
                score: 0
            },
            {
                trait: 'kreatywny',
                description: 'ma oryginalne pomysły, ma dobrą intuicję, pomysłowy',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'produktywny',
                description: 'ambitny, dążący do celu, skuteczny',
                color: 'red',
                score: 0
            }
        ],
        [
            {
                trait: 'uparty',
                description: 'nie rezygnuje, nonkonformista, odporny na wpływ',
                color: 'green',
                score: 0
            },
            {
                trait: 'perfekcyjny',
                description: 'dąży do doskonałości, dba o szczegóły, pedantyczny',
                color: 'blue',
                score: 0
            },
            {
                trait: 'nieemocjonalny',
                description: 'lekceważy emocje innych, opanowany, wyniosły',
                color: 'red',
                score: 0
            },
            {
                trait: 'impulsywny',
                description: 'działający szybko, energiczny, bez hamulców',
                color: 'yellow',
                score: 0
            }
        ],
        [
            {
                trait: 'ciepły',
                description: 'serdeczny, życzliwy, łagodny',
                color: 'green',
                score: 0
            },
            {
                trait: 'entuzjastyczny',
                description: 'energiczny, zapalczywy, inspirujący',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'dzielny',
                description: 'odważny, śmiały, nieustraszony',
                color: 'red',
                score: 0
            },
            {
                trait: 'dokładny',
                description: 'ostrożny, perfekcyjny, nie popełnia błędów',
                color: 'blue',
                score: 0
            }
        ],
        [
            {
                trait: 'nietowarzyski',
                description: 'nie kontaktowy, skryty, zamknięty w sobie',
                color: 'blue',
                score: 0
            },
            {
                trait: 'niewdzięczny',
                description: 'niedbały o innych, nieczuły, urażający',
                color: 'red',
                score: 0
            },
            {
                trait: 'egocentryczny',
                description: 'zagadujący na śmierć, ślepy na innych, podłoniety sobą',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'bierny',
                description: 'podporządkowany, nieasertywny, nieagresywny',
                color: 'green',
                score: 0
            }
        ],
        [
            {
                trait: 'rozmowny',
                description: 'ekspresyjny, towarzyski, przyjazny',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'zdecydowany',
                description: 'wyraźny, podejmujący decyzję, działający',
                color: 'red',
                score: 0
            },
            {
                trait: 'analityczny',
                description: 'badający, analizujący, oceniający',
                color: 'blue',
                score: 0
            },
            {
                trait: 'lojalny',
                description: 'wierny, można na nim polegać, stały',
                color: 'green',
                score: 0
            }
        ],
        [
            {
                trait: 'komenderujący',
                description: 'mocny, arogancki, domatyczny',
                color: 'red',
                score: 0
            },
            {
                trait: 'niespójny',
                description: 'niecierpliwy, nakręcony, nie relaksuje się',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'powolny',
                description: 'wolniejszy od innych, pracuje powoli, zwlekający',
                color: 'green',
                score: 0
            },
            {
                trait: 'zamartwiający się',
                description: 'lękliwy, pełen obaw, zmartwiony',
                color: 'blue',
                score: 0
            }
        ],
        [
            {
                trait: 'pewny',
                description: 'pewny siebie, zdecydowany, mocno osadzony',
                color: 'red',
                score: 0
            },
            {
                trait: 'krytycznie myślący',
                description: 'właściwie analizuje, dokładny, precyzyjny',
                color: 'blue',
                score: 0
            },
            {
                trait: 'przyjazny',
                description: 'sympatyczny, miły, pogodny',
                color: 'green',
                score: 0
            },
            {
                trait: 'zabawowy',
                description: 'żywy, zabawny, pełen humoru',
                color: 'yellow',
                score: 0
            }
        ],
        [
            {
                trait: 'niestały',
                description: 'nieodpowiedzialny, nie można polegać, niepunktualny',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'zależny',
                description: 'polega na innych, niepewny siebie, chwiejny',
                color: 'green',
                score: 0
            },
            {
                trait: 'surowy',
                description: 'narzuca reguły, niecierpliwy, karzacy',
                color: 'blue',
                score: 0
            },
            {
                trait: 'nietaktowny',
                description: 'nieokrzesany, niedelikatny, depczący po odciskach',
                color: 'red',
                score: 0
            }
        ],
        [
            {
                trait: 'planujący',
                description: 'bada za i przeciw, precyzyjny, rozważny',
                color: 'blue',
                score: 0
            },
            {
                trait: 'godny zaufania',
                description: 'można na nim polegać, uczciwy, można mu wierzyć',
                color: 'green',
                score: 0
            },
            {
                trait: 'otwarty',
                description: 'szczery, komunikatywny, ekspresyjny',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'śmiały',
                description: 'odważny, działa z rozmachem, nieustraszony',
                color: 'red',
                score: 0
            }
        ],
        [
            {
                trait: 'niezdecydowany',
                description: 'zbierający dane, niewyraźny, wahający się',
                color: 'green',
                score: 0
            },
            {
                trait: 'ostrożny',
                description: 'nieufny, dokładny, rozważny',
                color: 'blue',
                score: 0
            },
            {
                trait: 'twardy',
                description: 'logiczny, nieugięty, dopnie swego',
                color: 'red',
                score: 0
            },
            {
                trait: 'niekonsekwentny',
                description: 'nielogiczny, pełen sprzeczności, kreatywny',
                color: 'yellow',
                score: 0
            }
        ],
        [
            {
                trait: 'tolerancyjny',
                description: 'cierpliwy, szanujący, akceptujący',
                color: 'green',
                score: 0
            },
            {
                trait: 'wszechstronny',
                description: 'elastyczny, twórczy, pomysłowy',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'zdecydowany',
                description: 'ambitny, nieustępliwy, stanowczy',
                color: 'red',
                score: 0
            },
            {
                trait: 'precyzyjny',
                description: 'dokładny, konkretny, odpowiedzialny',
                color: 'blue',
                score: 0
            }
        ],
        [
            {
                trait: 'drażliwy',
                description: 'szybko się obraża, wrażliwy, łatwo urazić',
                color: 'blue',
                score: 0
            },
            {
                trait: 'dominujący',
                description: 'nieelastyczny, agresywny, rywalizujący',
                color: 'red',
                score: 0
            },
            {
                trait: 'wyszczekany',
                description: 'gaduła, mówi co myśli, asertywny',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'wycofany',
                description: 'łatwy w kontakcie, bierny, niegroźny',
                color: 'green',
                score: 0
            }
        ],
        [
            {
                trait: 'wspaniałomyślny',
                description: 'niesamolubny, potrafi dawać, lubi się dzielić',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'bezpośredni',
                description: 'przywódczy, zdecydowany, silny',
                color: 'red',
                score: 0
            },
            {
                trait: 'spostrzegawczy',
                description: 'otwarty na informacje, obserwujący, rozróżniający',
                color: 'blue',
                score: 0
            },
            {
                trait: 'tolerancyjny',
                description: 'cierpliwy, łatwo się przystosowuje, dostosowuje się',
                color: 'green',
                score: 0
            }
        ],
        [
            {
                trait: 'kontrolujący',
                description: 'manipulujący, wymuszający, dyrygujący',
                color: 'red',
                score: 0
            },
            {
                trait: 'nadgorliwy',
                description: 'pochopny, impulsywny, niespokojny',
                color: 'yellow',
                score: 0
            },
            {
                trait: 'uczuciowy',
                description: 'wrażliwy, głęboko przeżywający, delikatny',
                color: 'green',
                score: 0
            },
            {
                trait: 'nieufny',
                description: 'podejrzliwy, baczny, nastawiony obronnie',
                color: 'blue',
                score: 0
            }
        ]
    ],
    currentTraitsIndex: 0,
    currentDescription: 0,
    totalScore: {
        red: 0,
        green: 0,
        blue: 0,
        yellow: 0
    },
    totalScoreSorted: {},
    interpersonal: {
        red: {
            color: 'red',
            name: 'Czerwony',
            description: 'Masz naturalną skłonność i dar do przewodzenia innymi i robisz to siłą swojej osobowości. Potrafisz generować u ludzi zaangażowanie, ' +
                'zaufanie i motywację. Jesteś skoncentrowany na działaniu "tu i teraz" - nie rozpamiętujesz porażek z przeszłości. Stawiasz sobie i innym ambitne cele ' +
                'i oczekujesz ich realizacji bez szukania wymówek. Nowe zadania traktujesz jak wyzwania, które napędzają Cię do działania a w ich realizacji ' +
                'koncentrujesz się na celu a nie na emocjonalnych aspektach. Dzięki temu działasz szybko i skutecznie. Z dużą łatwością podejmujesz decyzje opierając się na faktach. ' +
                'Jesteś nastawiony na rozwiązywanie problemów. W komunikacji jesteś bezpośredni, koncentrujesz się na kluczowych informacjach. Cenisz swój czas i potrafisz umiejętnie zarządzać sobą w czasie. W sytuacjach trudnych nie boisz się ' +
                'konfrontacji i jasnego wyrażania swojego zdania. Dbasz o dobrą organizację swojej pracy, oraz szybkie i sprawne działanie. Nie boisz się odpowiedzialności, ' +
                'oraz niezależności w działaniu. Często domagasz się akceptacji swoich pomysłów wierząc w słuszność podjętych przez siebie decyzji. Jesteś pełen zapału, dlatego unikasz braku aktywności.',
            positive: 'Proaktywność, dynamika w działaniu, zdolność do szybkiego podejmowania dobrych decyzji, konsekwencja w dążeniu do realizacji celu, ' +
                'niezależność myślenia, wysoka samodzielność i silna determinacja w działaniu (szybko i dużo), umiejętność myślenia strategicznego i rozwiązywania problemów.',
            negative: 'Impulsywność, duża skłonność do ryzyka, miewasz trudność w rozumieniu i akceptacji poglądów oraz uczuć innych przez co możesz być postrzegany jako nieczuły i surowy, ' +
                'brak cierpliwości, nie uznajesz zasad i ograniczeń narzucanych arbitralnie, miewasz trudność w wyrażaniu własnych emocji, przez co otoczenie może źle odczytywać Twoje prawdziwe intencje.',
            stress: {
                a: '- brak możliwości podejmowania decyzji/ samodzielnego działania,' +
                    '\n- brak wyzwań (praca oparta na rutynie),' +
                    '\n- nie osiąganie zamierzonych rezultatów,' +
                    '\n- marnowanie Twojego czasu i nadwyrężanie Twojej cierpliwości,' +
                    '\n- szablonowa praca oparta na rutynie,' +
                    '\n- poczucie braku kontroli,' +
                    '\n- sugestie żebyś się wyciszył/ uspokoił',
                b: '- rozdrażnienie, niepokój, frustracja,' +
                    '\n- impulsywność i ekspresyjnie wyrażana złość,' +
                    '\n- wchodzisz w bezwzględną konfrontację',
                c: '- aktywność fizyczna'
            }
        },
        blue: {
            color: 'blue',
            name: 'Niebieski',
            description: 'Jesteś perfekcjonistą skoncentrowanym na przyszłości. Lubisz zgłębiać i rozpracowywać problemy. Działasz bardzo precyzyjnie i z dużą rozwagą. ' +
                'Analizujesz wszystko szczegółowo, próbując wyeliminować wszelkie zagrożenia na drodze do realizacji celu. Jesteś ostrożny w podejmowaniu decyzji. Bardzo ważna dla Ciebie jest obiektywna ocena sytuacji. ' +
                'Dużą wagę przykładasz do planowania, ponieważ nie lubisz i źle tolerujesz utratę kontroli. W realizacji zadania koncentrujesz się na zadaniu a nie na jego emocjonalnych aspektach, ' +
                'dzięki czemu jesteś odporny na dystraktory. Najlepiej czujesz się sam ze sobą (lub w małym gronie zaufanych osób) dlatego chętnie realizujesz zadania w samotności, ' +
                'za które sam ponosisz odpowiedzialność. Dla poczucia szczęścia nie potrzebujesz wielu bodźców z zewnątrz, a większość energii czerpiesz ze swojego życia wewnętrznego. ' +
                'W realizacji zadania, oraz w komunikacji koncentrujesz się na faktach nie na emocjach. Trudno wyprowadzić Ciebie z równowagi, a Twoja mowa ciała rzadko komunikuje Twoje emocje. ' +
                'Działasz w oparciu o zasady, które szanujesz. Z przyjemnością realizujesz zadania oparte na konkretach i na liczbach, które są powtarzalne i mają wypracowany ' +
                'szablon/ instrukcję działania. Nie masz potrzeby angażowania się w życie emocjonalne innych a we współpracy koncentrujesz się na zadaniu. ' +
                'W związku z tym możesz być postrzegany jako osoba dystansująca się. U innych cenisz przede wszystkim kompetencje, oraz dokładność w działaniu.\n',
            positive: 'Precyzja w działaniu, nastawiony na jakość, wysokie umiejętności analityczne, sumienność, staranność i odpowiedzialność, trudno wyprowadzić Cię z równowagi.',
            negative: 'Mała pewność siebie, nieumiejętność podejmowania szybkich decyzji, niski poziom odporności na stres, pesymizm, ścisła współpraca oparta na relacjach, ' +
                'ciągłe poszukiwanie nowych informacji sprzyja pogrążeniu w analizach, bardzo uboga wręcz niezauważalna “mowa ciała”.',
            stress: {
                a: '- kwestionowanie Twoich decyzji i krytykowanie Twojej pracy,' +
                    '\n- osoby nie przestrzegające zasad, które działają niezgodnie z przepisami,' +
                    '\n- spontaniczne i nieprzemyślane decyzje: szefa, współpracowników,' +
                    '\n- każdy rodzaj ryzyka,' +
                    '\n- nieprzewidywalność: nieplanowane zmiany w zespole,' +
                    '\n- brak poczucia kontroli, brak wiedzy i wglądu w to co się dzieje wokół Ciebie,' +
                    '\n- gdy ktoś zarzuca Ci ścisłe trzymanie się przepisów i drwi z tego, jaki jesteś zasadniczy lub gdy ktoś zarzuci Ci działanie wbrew zasadom,' +
                    '\n- osoby, które nadmiernie skupiają się na aspektach emocjonalnych,' +
                    '\n- kiedy ktoś Ci mówi żebyś się sprężał, myślał pozytywnie albo zaakceptował i polubił sytuację',
                b: '- jesteś przesadnie pesymistyczny,' +
                    '\n- robisz się nieznośnie czepialski lub całkowicie obojętny,' +
                    '\n- jesteś nadmiernie krytyczny wobec innych - potrafisz wytykać najmniejsze błędy innym lub wręcz przeciwnie - stajesz się depresyjny i wycofany,' +
                    '\n- unikasz kontaktu wzrokowego',
                c: '- potrzebujesz całkowitego odosobnienia abyś miał czas i przestrzeń do namysłu i opanowania stresu'
            }
        },
        green: {
            color: 'green',
            name: 'Zielony',
            description: 'Jesteś osobą lojalną, spokojną, bezkonfliktową, godną zaufania. W realizacji zadania koncentrujesz się na “tu i teraz”. Cenisz sobie pracę zespołową, opierając się na wzajemnych zobowiązaniach. ' +
                'Nie lubisz ekspozycji, ani wyzwań. Swoje zadania realizujesz zazwyczaj po cichu, bez rozgłosu wokół siebie. Charakteryzuje Cię niski poziom asertywności przy bardzo wysokim ' +
                'poziomie cierpliwości, opanowania i empatii. Dla Ciebie najważniejsze są relacje z ludźmi co nie oznacza, że zadanie nie jest dla Ciebie ważne, jednak w jego realizacji istotną rolę odgrywają aspekty ' +
                'emocjonalne. Może to prowadzić do braku umiejętności wyrażania negatywnego feedbacku z lęku przed utratą relacji/ akceptacji ze strony innych, oraz do unikania podejmowania ' +
                'prób rozwiązywania sytuacji konfliktowych. Cenisz sobie współpracę, jednak najlepiej funkcjonujesz w małych zespołach z ludźmi których dobrze znasz i darzysz sympatią. ' +
                'Przydzielenie Cię do “złego zespołu”, gdzie pracuje osoba, którą nie darzysz sympatią znacząco obniża Twoją produktywność. Nie ujawniasz swoich negatywnych emocji na zewnątrz, ' +
                'aby nie narażać przez to relacji z innymi. Masz zdolność dostrzegania i uwzględniania wielu perspektyw. Doceniasz wartość dialogu w poszukiwaniu najlepszych rozwiązań. ' +
                'W relacjach z innymi kierujesz się otwartością i zaufaniem dzięki czemu z dużą łatwością zjednujesz sobie ludzi i przekonujesz do swoich rozwiązań. Jesteś dobrze zorganizowany, ' +
                'cierpliwy i konkretny. Do pracy podchodzisz w sposób uporządkowany, aby w jak największym stopniu przyczynić się do osiągnięć zespołu/firmy. Nie przepadasz za zmianami, ' +
                'nawet jeśli dana zmiana jest konieczna odraczasz ją w czasie. Jesteś odporny na dystraktory, dzięki czemu nie masz problemów z zadaniami, które wymagają większego wysiłku ' +
                'poznawczego. Potrafisz zjednywać sobie ludzi i skutecznie motywować ich do pracy. A dzięki swojej umiejętności słuchania wzbudzasz zaufanie i gotowość do większego zaangażowania.',
            positive: 'Lojalność, dokładność, stabilność, wytrwałość w działaniu, rozwaga, umiejętność budowania pozytywnych relacji.',
            negative: 'Problem z wyznaczaniem priorytetów gdy w grę wchodzą emocje, opór przed zmianą, lęk przed brakiem akceptacji, silna potrzeba aprobaty ze strony innych, trudność z delegowaniem zadań.',
            stress: {
                a: '- brak poczucia bezpieczeństwa,' +
                    '\n- utrata stabilności w obliczu ciągłych nieuzasadnionych zmian,' +
                    '\n- nie zrealizowanie celu/ niedokończona praca,' +
                    '\n- konflikty,' +
                    '\n- przymusowe bycie w centrum uwagi/ wywoływanie na forum do udzielania odpowiedzi (nie radzisz sobie z ekspozycją społeczną),' +
                    '\n- publiczna, otwarta krytyka,' +
                    '\n- praca z osobami za którymi nie przepadasz personalnie,' +
                    '\n- konflikty (nawet mała potyczka słowna potrafi przewartościować Twój stosunek do innej osoby)',
                b: '- totalna obojętność,' +
                    '\n- nieokazywanie emocji (wewn. czujesz się beznadziejnie, ale nie okazujesz tego na zewn.),' +
                    '\n- zdystansowanie (mowa ciała robi się sztywna i oszczędna),' +
                    '\n- stajesz się oziębły i nieprzychylny w stosunku do wszystkich również osób bliskich' +
                    '\n- problem z podejmowaniem decyzji (paraliżuje Cię strach przed porażką),' +
                    '\n- obwinianie się i całkowita blokada na działanie,' +
                    '\n- stajesz się uparty i możesz nieświadomie prowokować otoczenie (odmawiasz działania nawet w sytuacjach, gdy wiesz, że działanie jest słuszne)',
                c: '- chwilowe obniżenie wymagań zawodowych (musisz złapać oddech),' +
                    '\n- aktywny lub bierny relaks pozwalający na odprężenie (sport, praca w ogrodzie, sen, czytanie książki)'
            }
        },
        yellow: {
            color: 'yellow',
            name: 'Żółty',
            description: 'Jesteś osobą kreatywną, komunikatywną, pozytywnie inspirującą i pełną życia. Dla Ciebie najważniejsze są relacje oraz pozytywny obraz siebie w oczach innych. ' +
                'Entuzjastycznie podchodzisz do nowych pomysłów i zmian. Słowa „nie da się, nie wiem, to nierealne”, są zaprzeczeniem Twojej ideologii życia, jednak Twój nierealny optymizm ' +
                'czasami może doprowadzić Ciebie do kłopotów, ponieważ masz skłonność do działań impulsywnych. Potrzebujesz nieustannie nowych bodźców z zewnątrz - w związku z czym szybko nudzą Cię monotonne ' +
                'zadania, szczególnie te długoterminowe. W działaniu koncentrujesz się na przyszłości. Jesteś spontaniczny, pewny siebie. Dużą satysfakcję czerpiesz z kontaktów społecznych. ' +
                'Jesteś osobą bardzo towarzyską i potrafisz motywować innych. Źle radzisz sobie z krytyką. Krytyka Cię blokuje i frustruje. Lepiej działają ' +
                'na Ciebie sugestie do zmiany niż bezpośrednie negowanie Twoich pomysłów. W realizacji zadań ważniejsze są dla Ciebie aspekty emocjonalne niż fakty. Nie lubisz rozważać możliwości ' +
                'i preferujesz krótkoterminowe zadania. Jesteś bardzo ekspresyjny i przejawiasz wiele zachowań nieformalnych. Lubisz być w centrum uwagi, a swoją pozytywną energią zdobywasz sympatię ' +
                'współpracowników. Jesteś wrażliwy na dystraktory które szybko i skutecznie odwracają Twoją uwagę od zadania - ważne więc abyś korzystał z pomocy zewnętrznych motywatorów i przypominaczy.',
            positive: 'Optymizm, wysoki poziom kreatywności, otwartość na zmianę, umiejętność zjednywania sobie ludzi, nie rozpamiętujesz porażek i nie chowasz urazy do nikogo, ' +
                'szybko się uspokajasz, bardzo szybko akceptujesz zmiany.',
            negative: 'Utrzymanie systematyczności, niskie umiejętności analityczne, wysoka impulsywność, nierealistyczne planowanie swojej pracy - nadmierny optymizm, silna potrzeba aprobaty ' +
                'ze strony innych, bagatelizowanie problemów.',
            stress: {
                a: '- brak poczucia akceptacji ze strony innych,' +
                    '\n- nudne, rutynowe i przewidywalne zadania,' +
                    '\n- bycie niezauważonym,' +
                    '\n- poczucie odizolowania od innych,' +
                    '\n- utrata sympatii innych,' +
                    '\n- kwestionowanie Twojej natury (śmiech w pracy jest niepoważny),' +
                    '\n- bezpośrednia krytyka,' +
                    '\n- wykłócanie się o bzdury,' +
                    '\n- ignorowanie Ciebie, uciszanie lub pomijanie,' +
                    '\n- sceptycyzm i pesymizm innych wobec Twoich pomysłów,' +
                    '\n- gdy czujesz brak sympatii, obniża się Twoja produktywność i to prowadzi u Ciebie do stresu',
                b: '- zwracasz uwagę na siebie bardziej niż zwykle,' +
                    '\n- niepokój,' +
                    '\n- frustracja,' +
                    '\n- nietaktowne zachowania' +
                    '\n- mówisz za dużo, gestykulujesz przesadnie,' +
                    '\n- wchodzisz w konfrontacje',
                c: '- zrób coś fajnego co wyrwie się z rutyny: wyjście ze znajomymi po pracy, kino, spacer, pizza' +
                    '\n- żartuj, śmiej się, zabaw się'
            }
        }
    },
    relations: {
        red: {
            color: 'red',
            name: 'Czerwony',
            relationPlus: '- należy uważnie słuchać "osoby czerwonej", czego ona od nas oczekuje,' +
                '\n- lubi konfrontacje,' +
                '\n- przygotuj się przed spotkaniem aby nie stracić wątku, “czerwony” często przerywa (problem z aktywnym słuchaniem),' +
                '\n- stosuj w rozmowie krótkie i zwięzłe komunikaty (nie marnuj jego czasu),' +
                '\n- przygotuj się na fakty i liczby i bądź profesjonalny: logicznie przedstawiaj problemy,' +
                '\n- przekazuj informacje “wprost”, bądź bezpośredni, zwięzły i konkretny,' +
                '\n- skup się na celach i na wynikach w rozmowie,' +
                '\n- jasno wyrażaj swoje uczucia i wątpliwości,' +
                '\n- przygotuj się na bezpośrednie, krytyczne uwagi z jego strony,' +
                '\n- poświęcaj mu uwagę i okazuj szacunek,' +
                '\n- opieraj się na logice swoich argumentów, a nie na jej emocjonalnych aspektach,' +
                '\n- angażuj go w proces decyzyjny, pytaj o jego zdanie',
            relationMinus: '- w sytuacji kryzysowej nie tłumacz się, nie usprawiedliwiaj, szukaj porozumienia przedstawiając możliwości wyjścia z trudnej sytuacji, naprawienia błędu; ' +
                '"Osoba czerwona" nie rozpamiętuje przeszłości - oczekuje konstruktywnego działania w celu rozwiązania problemu,' +
                '\n- nie toleruje słowa “nie”, “nie da się”, “nie zrobię tego”,' +
                '\n- nie manipuluj,' +
                '\n- nie rozpamiętuj krytycznych uwag i nie bierz wszystkiego do siebie, tylko skoncentruj się na działaniu,'
        },
        blue: {
            color: 'blue',
            name: 'Niebieski',
            relationPlus: '- przygotuj się na fakty i liczby i bądź dokładny i profesjonalny: logicznie przedstawiaj problemy, skup się na celach i wynikach,' +
                '\n- szanuj jego czas - nie spóźniaj się na spotkania,' +
                '\n- bądź przygotowany do rozmowy, skoncentruj się na ważnych i znaczących informacjach i przedstaw je w sposób zwięzły (przysłowiowe „lanie wody” i chaos, wyprowadza go z równowagi)' +
                '\n- przestrzegaj zasad, procedur,' +
                '\n- skoncentruj się na treści komunikatu, celu rozmowy,' +
                '\n- zadbaj o jego poczucie bezpieczeństwa w relacji (bądź szczery),' +
                '\n- bądź lojalny, doceniaj jego starania,' +
                '\n- w rozmowie zawsze bądź opanowany i spokojny',
            relationMinus: '- nie oczekuj spontaniczności w działaniu, wszystko wymaga solidnego zaplanowania,' +
                '\n- nie oczekuj, że szybko wybaczy i zapomni urazę,' +
                '\n- nie doradzaj, nie krytykuj publicznie,' +
                '\n- nie wymagaj natychmiastowych działań i bezpośrednich odpowiedzi na trudne pytania,' +
                '\n- nie wchodź z nim w bezpośrednią konfrontację, nie krytykuj, nie wytykaj błędów,' +
                '\n- nie opowiadaj o swoich przeżyciach, doświadczeniach ponieważ to go irytuje (przejawia niechęć do emocji innych),' +
                '\n- pozwól mu zebrać myśli - nigdy go nie pospieszaj,' +
                '\n- zawsze bądź szczery, nie manipuluj, nie spoufalaj się'
        },
        green: {
            color: 'green',
            name: 'Zielony',
            relationPlus: '- dbaj o relacje, okazuj zainteresowanie tym jak się czuje, czy możesz mu w czymś pomóc,' +
                '\n- w rozmowie (szczególnie trudnej) bądź zawsze opanowany i przedstawiaj merytoryczne argumenty w spokojny sposób, ' +
                'i zawsze obserwuj jego mowę ciała czy jest spójna z komunikatem werbalnym (“zielony” ukrywa negatywne emocje),' +
                '\n- uwzględniaj jego opinie - ponieważ z uwagi na jego duży poziom uważności na to co dzieje się wokół niego - potrafi spojrzeć na różne sytuacje z wielu perspektyw,' +
                '\n- pozwól mu dokonywać analizy sytuacji i dzielić się swoimi spostrzeżeniami,' +
                '\n- dopytuj jeśli masz wątpliwość co do polecenia, ponieważ “zielony” często posługuje się “ogólnikami”,' +
                '\n- bądź czujny na wysyłane komunikaty: wielu rzeczy “zielony” nie mówi wprost, prowadzi gry słowne, urodzony dyplomata, sam musisz rozszyfrować o co mu chodzi,' +
                '\n- w rozmowie zadawaj pytania otwarte i uważnie słuchaj (nigdy nie ma u niego prostych odpowiedzi, zawsze to zależy),' +
                '\n- jeśli potrzebujesz jego pomocy - poproś, “zielony” zawsze działa w dobrej woli, jest pomocny,' +
                '\n- jeśli popełnisz błąd - zawsze otwarcie przyznaj się do niego',
            relationMinus: '- nie wywieraj na nim presji, pozwól mu działać we własnym tempie,' +
                '\n- nie narażaj na niespodziewaną publiczną ekspozycję (“nie wywołuj do tablicy”),' +
                '\n- nie zmieniaj mu środowiska pracy jeśli nie jest to uzasadnione i konieczne,' +
                '\n- nie wymagaj od niego asertywnej postawy i wchodzenia w bezpośrednią konfrontację,' +
                '\n- nie przerywaj podczas rozmowy, aby nie uznał, że to co ma do powiedzenia jest mało ważne,' +
                '\n- nie pospieszaj, daj mu przestrzeń i czas do wyrażania swoich poglądów/ opinii; “Zielony” ma do powiedzenia wiele ważnych rzeczy jednak ' +
                'nie ma siły przebicia, i czasami rezygnuje z prawa do głosu nie mogąc się przedrzeć,' +
                '\n- nie oczekuj natychmiastowej odpowiedzi, a brak odpowiedzi nigdy nie traktuj jako zgody,' +
                '\n- nigdy nie przyjmuj dominującej postawy, ponieważ będzie to odebrane jako zagrożenie'
        },
        yellow: {
            color: 'yellow',
            name: 'Żółty',
            relationPlus: '- bądź miły i serdeczny, pozwalaj na luźne rozmowy, buduj więź i podtrzymuj relację (zadbaj o to żeby podkreślić to co macie wspólnego ze sobą),' +
                '\n- w rozmowie dot. zadania koncentruj się na ogólnych informacjach, jeśli będzie dotyczyła zbyt wielu faktów i będzie zbyt teoretyczna - stracisz jego uwagę,' +
                '\n- koncentruj się na przyszłości, “żółty” woli rozmawiać o przyszłości, jest bowiem wizjonerem czasami oderwanym od rzeczywistości,' +
                '\n- jeśli nie zgadzasz się z jego pomysłem możesz powiedzieć: "świetny pomysł ale ja zrobiłbym to tak, co o tym sądzisz?",' +
                '\n- w przekazywanych informacjach koncentruj się również na emocjach,' +
                '\n- ponieważ ma problem z organizacją pracy, kończeniem zadań, oraz z pilnowaniem terminów - kontroluj (nie bezpośrednio) i stale podtrzymuj motywację; Zastosuj metodę “pikowania“ z uśmiechem: “jak tam”, “pamiętasz?”, “działasz?”,' +
                '\n- powierzaj do zrobienia jedną rzecz na raz,' +
                '\n- zawsze parafrazuj wasze ustalenia, żeby sprawdzić czy dobrze się rozumiecie w zakresie zadania,' +
                '\n- doceniaj starania, chwal za postępy (pochwały są dla “żółtych” równoznaczne z emocjonalnym bezpieczeństwem),' +
                '\n- poświęcaj mu uwagę i słuchaj go, ale ponieważ często “żółty” nagina rzeczywistość - zachowaj czujność w wysłuchiwaniu jego opowieści,' +
                '\n- w relacji możesz pozwolić sobie na kontakt fizyczny: dotykanie, poklepywanie, głaskanie (dla nich kontakt fizyczny jest oznaką szczerej i serdecznej więzi międzyludzkiej),' +
                '\n- okazuj akceptację i zrozumienie,' +
                '\n- śmiej się z jego żartów a zyskasz jego sympatię',
            relationMinus: '- nigdy nie krytykuj szczególnie w czyjejś obecności (pamiętaj, że “żółty” jest bardzo wrażliwy na krytykę),' +
                '\n- nie uciszaj, pozwól na werbalne wyrażanie siebie,' +
                '\n- nie kontroluj bezpośrednio, ponieważ w ten sposób wyrażasz swój brak zaufania,' +
                '\n- nie blokuj jego ekspresji w zachowaniu, pozwól mu wyrażać swoje emocje,' +
                '\n- nie krytykuj jego pomysłów w ten sposób pobudzisz jego kreatywność (potrafi wyrzucić z siebie dużo pomysłów nie zawsze dobrych)'
        }
    }
}
