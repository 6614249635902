<template>
    <div>
        <div style="padding-bottom: 20px">
            <button class="btn disabled"
                    style="margin: 20px 45px 20px 0; color: white; opacity: 1;">
                Profil Interpersonalny
            </button>
            <router-link :to="{name: 'relations'}">
                <button class="btn"
                    style="margin: 20px 0 20px 0; color: white; opacity: 0.7;">
                    Zarządzanie Relacjami
                </button>
            </router-link>
        </div>
        <div class="container border mb-2 pb-4" v-for="(score, id) in totalScore" :key="id">
            <div class="title pt-4 pb-1 mb-3" v-bind:style="{color: getScoreColorHex(interpersonal[score[0]]['color'])['interColor']}">{{interpersonal[score[0]]['name']}}</div>
            <div style="text-align: justify">{{interpersonal[score[0]]['description']}}</div>
            <div class="title2 pt-2 pb-2">Twoje mocne strony to:</div>
            <div style="text-align: justify">{{interpersonal[score[0]]['positive']}}</div>
            <div class="title2 pt-2 pb-2">Twoje słabe strony to:</div>
            <div style="text-align: justify">{{interpersonal[score[0]]['negative']}}</div>
            <div class="title2 pt-2 pb-2">Co Cię stresuje:</div>
            <div style="text-align: left">{{interpersonal[score[0]]['stress']['a']}}</div>
            <div class="title2 pt-2 pb-2">Wskaźniki stresu u Ciebie:</div>
            <div style="text-align: left">{{interpersonal[score[0]]['stress']['b']}}</div>
            <div class="title2 pt-2 pb-2">Najbardziej skuteczny dla Ciebie sposób radzenia sobie ze stresem:</div>
            <div style="text-align: left">{{interpersonal[score[0]]['stress']['c']}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Interpersonal",
    metaInfo: {
        title: 'Profil Interpersonalny'
    },
    data() {
        return {
            interpersonal: [],
            totalScore: []
        }
    },
    mounted() {
        this.$store.commit('getTotalScoresSortedFromLocalStorage')
        this.totalScore = this.$store.getters.getTotalScoreSorted
        this.interpersonal = this.$store.getters.getInterpersonal
    },
    methods: {
        getScoreColorHex: function (color) {
            let interColor

            switch (color) {
                case 'red':
                    interColor = '#DE362F'

                    break;
                case 'blue':
                    interColor = '#2A54C7'

                    break;
                case 'green':
                    interColor = '#2FDE6C'

                    break;
                case 'yellow':
                    interColor = '#D9DE45'

                    break;
            }

            return {'interColor': interColor}
        }
    }
}
</script>

<style scoped>
    .title {
        font-weight: bold;
        font-size: 20px;
        text-transform: uppercase;
        color: #763460;
        text-align: left;
        border-bottom: 3px solid #763460;
    }

    .title2 {
        font-weight: bold;
        color: #763460;
        text-align: left;
        font-size: 16px;
    }

    .container {
        background-color: #ffffff;
        border-radius: 10px;
        height: 100%;
        white-space: pre-line;
        font-size: 15px;
    }

    .btn {
        border-radius: 8px;
        background-color: #c73468;
        font-size: large;
        font-weight: bold;
        width: 40%;
    }

    .btn:focus {
        box-shadow: none;
    }
</style>
