<template>
    <div>
        <div style="padding-bottom: 20px">
            <router-link :to="{name: 'interpersonal'}">
                <button
                    class="btn"
                    style="margin: 20px 45px 20px 0; color: white; opacity: 0.7;">
                    Profil Interpersonalny
                </button>
            </router-link>
            <button
                class="btn disabled"
                style="margin: 20px 0 20px 0; color: white; opacity: 1;">
              Zarządzanie Relacjami
            </button>
        </div>
            <div class="mb-2" v-for="(score, id) in totalScore" :key="id">
                <div class="container pb-2">
                    <div class="title pt-4 pb-1" v-bind:style="{color: getScoreColorHex(relations[score[0]]['color'])['interColor']}">{{relations[score[0]]['name']}}</div>
                    <div class="p-2 pb-0" style="font-size: 15px;">
                        <p class="title2 pt-2"><i class="bi bi-check-lg"></i> Co należy robić</p>
                        <p class="pb-3" style="border-bottom: 3px solid #763460;">{{relations[score[0]]['relationPlus']}}</p>
                    </div>
                    <div class="containerBottom p-2 pt-0 pb-0">
                        <p class="title2"><i class="bi bi-x-lg"></i> Czego nie należy robić</p>
                        <p>{{relations[score[0]]['relationMinus']}}</p>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: "Relations",
    metaInfo: {
        title: 'Zarządzanie Relacjami'
    },
    data() {
        return {
            relations: [],
            totalScore: []
        }
    },
    mounted() {
        this.relations = this.$store.getters.getRelations
        this.$store.commit('getTotalScoresSortedFromLocalStorage')
        this.totalScore = this.$store.getters.getTotalScoreSorted
    },
    methods: {
        getScoreColorHex: function (color) {
            let interColor

            switch (color) {
                case 'red':
                    interColor = '#DE362F'

                    break;
                case 'blue':
                    interColor = '#2A54C7'

                    break;
                case 'green':
                    interColor = '#2FDE6C'

                    break;
                case 'yellow':
                    interColor = '#D9DE45'

                    break;
            }

            return {'interColor': interColor}
        }
    }
}
</script>

<style scoped>
    .title {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 3px solid #763460;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
    }

    .title2 {
        font-weight: bold;
        font-size: larger;
        text-transform: uppercase;
        margin-bottom: 10px
    }

    .btn {
        border-radius: 8px;
        background-color: #c73468;
        font-size: large;
        font-weight: bold;
        width: 40%;
    }

    .containerBottom {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: left;
        font-size: 15px;
    }

    .container {
        display: inline-block;
        position: relative;
        background-color: white;
        border-radius: 10px;
        text-align: left;
        white-space: pre-line;
        border: 1px solid lightgray;
    }

    .btn:focus {
        box-shadow: none;
    }
</style>