<template>
  <div class="body">
    <div id="app">
      <div class="container col-lg-8 col-12 p-1 pt-2" style="margin-bottom: 10px; color: #763460;">
        <h1 style="font-weight: bold;"><i class="bi bi-check2-circle"></i> Test kolorów osobowości</h1>
      </div>
      <div class="container col-lg-8 col-12 p-4">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'App',
        metaInfo: {
            title: 'Start',
            titleTemplate: '%s | Test kolorów osobowości'
        }
    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 60px 20px 60px 20px;
}
body {
  background: url("/assets/1.svg") no-repeat center fixed;
  background-size: cover;
}

.container {
    max-width: 770px !important;
}
</style>
