export default {
    resetCurrentTraitsIndex (state) {
        state.currentTraitsIndex = 0

        localStorage.setItem('currentTraitsIndex', state.currentTraitsIndex)
    },
    incrementCurrentTraitsIndex (state) {
        if (state.currentTraitsIndex < (state.traits.length - 1)) {
            state.currentTraitsIndex++

            localStorage.setItem('currentTraitsIndex', state.currentTraitsIndex)
        }
    },
    decrementCurrentTraitsIndex (state) {
        if (state.currentTraitsIndex > 0) {
            state.currentTraitsIndex--

            localStorage.setItem('currentTraitsIndex', state.currentTraitsIndex)
        }
    },
    getAllTraitsScoresFromLocalStorage (state) {
        state.traits.forEach((traitGroup, indexGroup) => {
            traitGroup.forEach((trait, index) => {
                state.traits[indexGroup][index].score = parseInt(localStorage.getItem(`traitScore/${indexGroup}/${index}`)) || 0
            });
        });
    },
    getTotalScoresFromLocalStorage (state) {
        state.totalScore = JSON.parse(localStorage.getItem('totalScore')) || state.totalScore
    },
    getTotalScoresSortedFromLocalStorage (state) {
        let totalScoreSorted = JSON.parse(localStorage.getItem('totalScore')) || state.totalScore,
            totalScoreArray = Object.entries(totalScoreSorted)

        state.totalScoreSorted = totalScoreArray.sort((a, b) => b[1] - a[1])
    },
    setCurrentTraitsIndex (state) {
        state.currentTraitsIndex = localStorage.getItem('currentTraitsIndex') || 0
    },
    setScoreToTrait (state, index) {
        let currentScores = [],
            currentScore = state.traits[state.currentTraitsIndex][index].score + 1

        state.totalScore[state.traits[state.currentTraitsIndex][index].color]++

        state.traits[state.currentTraitsIndex].forEach((trait) => {
            currentScores.push(trait.score)
        })

        currentScores.sort((a ,b) => { return a - b })

        currentScores.forEach((score) => {
            if (score !== 0 && currentScore === score) {
                currentScore++

                state.totalScore[state.traits[state.currentTraitsIndex][index].color]++
            }
        })

        if (currentScore > 4) {
            currentScore = 0

            state.totalScore[state.traits[state.currentTraitsIndex][index].color] -= 5
        }

        state.traits[state.currentTraitsIndex][index].score = currentScore

        localStorage.setItem(`traitScore/${state.currentTraitsIndex}/${index}`, currentScore)
        localStorage.setItem('totalScore', JSON.stringify(state.totalScore))
    }

}
