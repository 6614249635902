import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex);

const data = {
    state: state,
    mutations: mutations,
    getters: getters
}

const createStore = () => {
    return new Vuex.Store({
        modules: {
            data
        }
    });
};

export default createStore;
