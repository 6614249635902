<template>
    <div class="row justify-content-center mt-3">
        <div class="pb-3">
            <router-link :to="{name: 'homepage'}">
            <button class="btn btn1" style="color: white;">Strona startowa</button>
            </router-link>
            <router-link :to="{name: 'quiz'}">
            <button class="btn btn1" style="color: white;">Powrót do testu</button>
            </router-link>
            <router-link :to="{name: 'quiz'}">
            <button class="btn btn1 del" style="color: white;" v-on:click="clearStorage"><i class="bi bi-x-circle"></i> Usuń wyniki</button>
            </router-link>
        </div>
        <div class="col-md-8">
            <table class="table table-borderless col-md-8">
                <thead>
                    <tr>
                        <th style="border-top-left-radius: 10px;">Kolor</th>
                        <th style="border-top-right-radius: 10px;">Wynik</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(score, id) in totalScore" :key="id"
                        v-bind:style="{
                            backgroundColor: getScoreNameAndColorHex(score)['colorHex'],
                            fontWeight: getScoreNameAndColorHex(score)['fontWeight'],
                        }"
                    >
                        <td v-bind:style="{borderRight: '2px solid #fff'}">
                            {{getScoreNameAndColorHex(score)['scoreName']}}
                        </td>
                        <td>{{score[1]}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="description container border mt-2">
            Wynik powyżej 40 punktów oznacza, że masz dużą skłonność  do przejawiania danych zachowań.
            Wynik poniżej 35 punktów - przejawiasz tendencję do unikania tego typu zachowań.
            Wynik w przedziale między 35 a 40 punktów - możesz rzadziej przejawiać takie zachowania, lub, że działasz w tym stylu bez zdecydowania.<br>
            <br>Większość osób uzyskuje dwie większe liczby powyżej 40 i dwie mniejsze. Niektórzy mają tylko jeden wysoki wynik, a pozostałe poniżej 35 punktów.
            <br>Zdarzają się osoby mające trzy wyniki powyżej 40 punktów i jeden bardzo niski.
        </div>
        <div>
          <br>
          <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "Summary",
    metaInfo: {
        title: 'Podsumowanie'
    },
    data() {
        return {
            totalScore: []
        }
    },
    mounted() {
        this.$store.commit('getTotalScoresSortedFromLocalStorage')
        this.totalScore = this.$store.getters.getTotalScoreSorted
    },
    methods: {
        getScoreNameAndColorHex: function (score) {
            let scoreName,
                colorHex

            switch (score[0]) {
                case 'red':
                    scoreName = 'Czerwony'
                    colorHex = '#DE362F'

                    break;
                case 'blue':
                    scoreName = 'Niebieski'
                    colorHex = '#2A54C7'

                    break;
                case 'green':
                    scoreName = 'Zielony'
                    colorHex = '#2FDE6C'

                    break;
                case 'yellow':
                    scoreName = 'Żółty'
                    colorHex = '#D9DE45'

                    break;
            }

            return {'scoreName': scoreName, 'colorHex': colorHex}
        },
        clearStorage: function () {
            localStorage.clear();
        }
    }
}
</script>

<style scoped>
    table {
        font-size: large;
        font-weight: bold;
    }

    table thead {
        text-transform: uppercase;
        background-color: #c73468;
        color: white;
    }

    table tbody {
        background-color: white;
    }

    table thead tr th:first-of-type {
        border-right: 2px solid #fff;
    }

    table thead tr th {
        border-bottom: 4px solid #fff;
    }

    table tbody tr td {
        background-color: rgba(255,255,255, 0.4);
        color: #fff;
    }

    table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
    }

    table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }

    .description {
        text-align: justify;
        width: 90%;
        background-color: white;
        border-radius: 10px;
        padding: 15px;
        font-size: 15px
    }

    .btn {
        background-color: #c73468;
        font-size: large;
        font-weight: bold;
        border-radius: 8px;
        margin: 5px
    }

    .btn1 {
        background-color: #763460;
        font-size: 15px;
        font-weight: bold;
        border-radius: 8px;
        position: relative;
    }

    .del {
        background-color: #E87874;
        text-transform: uppercase;
    }

    .btn:focus {
        box-shadow: none;
    }
</style>
