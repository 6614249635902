export default {
    getCurrentTraits: state => {
        return state.traits[state.currentTraitsIndex]
    },
    getTotalScore: state => {
        return state.totalScore
    },
    getCurrentTraitsIndex: state => {
        return state.currentTraitsIndex
    },
    getTotalTraitsCount: state => {
        return state.traits.length
    },
    isNextTraitGroup: state => {
        return state.currentTraitsIndex < (state.traits.length - 1)
    },
    getCurrentDescriptions: state => {
        return state.currentDescription
    },
    getInterpersonal: state => {
        return state.interpersonal
    },
    getRelations: state => {
        return state.relations
    },
    getTotalScoreSorted: state => {
        return state.totalScoreSorted
    }
}
