<template>
    <div class="hello">
        <div class="container border mb-4">
            <div class="title">Kolorystyczny Profil Osobowości (4 Typy zachowań)</div>
            <div class="description">
                Formularz badania ma na celu: przeanalizować Twoją osobowość i dostarczyć wiedzy, która pomoże Ci w lepszej komunikacji z innymi.
                Każdy kolor jest ściśle powiązany z określonym zestawem cech osobowości, a znajomość swojej konfiguracji barw, a także typu osobowości ludzi “obok nas” -
                podnosi jakość nawiązywanych relacji i ułatwia osiąganie wspólnych celów.
            </div>
        </div>
        <div class="container border mb-4">
            <div class="title">Instrukcja</div>
            <div class="description">
                Test zawiera 16 rzędów głównych haseł. Każde hasło formułuje ogólną cechę osobowości - zdefiniowaną dodatkowymi pojęciami.
                Wszystkim hasłom należy przypisać cyfrę od 1 do 4 <b>(cyfry nie mogą się powtarzać)</b>.
                Konieczne jest uzupełnienie wszystkich pól, nawet jeśli kilka z pojęć nie określa Ciebie zbyt dokładnie.
                <br>
                <b>Pamiętaj!</b> Staraj się wybierać te odpowiedzi, które najbardziej Ciebie charakteryzują - taki jestem najczęściej,
                w większości sytuacji, kontaktów z innymi lub podczas większości zadań, które wykonuję.
                <br><br>
                    <div style="margin-left: 20px;">
                        4 - ze wszystkich czterech pojęć to słowo opisuje mnie najlepiej<br>
                        3 - ze wszystkich trzech pojęć to słowo opisuje mnie najlepiej<br>
                        2 - z pozostałych dwóch pojęć to słowo bardziej mnie opisuje<br>
                        1 - ze wszystkich czterech pojęć to słowo opisuje mnie najgorzej
                    </div>
                <br>
                By przypisać cyfry należy nacisnąć dane pole z wybraną krotnością, <b>jeżeli dana cyfra została już przypisana,
                będzie ona pominięta przy wyborze w innym haśle</b>.
                <br><br>
                <b style="font-size: 16px">Przykład:</b>
                <br><br>
                <img src="/assets/image.png" style="width: 100%; height: 100%;">
            </div>
        </div>
        <router-link :to="{name: 'quiz'}">
          <button class="btn" style="color:white; font-weight: bold; background-color: #c73468; font-size: larger;">Przejdź do testu</button>
        </router-link>
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'HelloScreen',
    metaInfo: {
        title: 'Start'
    },
    created() {
        this.$store.commit('resetCurrentTraitsIndex')
    }
}
</script>

<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    b{
        color: #763460;
    }

    .title {
        border-bottom: 3px solid #763460;
        padding-bottom: 5px;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: larger;
    }

    .container {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }

    .description {
      text-align: justify;
      font-size: 15px;
    }

    .btn:focus {
        box-shadow: none;
    }
</style>
