import HelloScreen from "../components/HelloScreen";
import Quiz from "../components/Quiz";
import Summary from "../components/Summary";
import Interpersonal from "@/components/Interpersonal";
import Relations from "@/components/Relations";

export default [
    {
        name: 'homepage',
        path: '/',
        component: HelloScreen
    },
    {
        name: 'quiz',
        path: '/quiz',
        component: Quiz
    },
    {
        name: 'summary',
        path: '/summary',
        component: Summary,
        redirect: '/summary/interpersonal',
        children: [
            {
                name: 'interpersonal',
                path: 'interpersonal',
                component: Interpersonal
            },
            {
                name: 'relations',
                path: 'relations',
                component: Relations
            }
        ]
    },
    {
      path: '*',
      redirect: '/'
    }
];
