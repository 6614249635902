<template>
    <div class="hello">
        <div style="font-weight: bold; font-size: 30px; color: #2c3e50; opacity: 0.5">Zestaw cech {{currentTraitsIndex + 1}} z {{totalTraitCout}}</div>
        <br>
        <fieldset class="row">
            <div class="col-12 col-sm-6"
                 v-for="(trait, index) in traits"
                 :key="trait.trait"
                 v-on:click="setScore(index)"
            >
                <button class="btn quiz mb-4 w-100 position-relative" style="color: white;">
                    <div style="width: 90%; margin-bottom: 2px;">{{trait.trait}}</div>
                    <div class="trait-description">{{trait.description}}</div>
                    <span class="trait-score">{{trait.score}}</span>
                </button>
            </div>
        </fieldset>

        <div class="progress mb-3">
            <div class="progress-bar" role="progressbar" v-bind:style="{width: (currentTraitsIndex + 1) / totalTraitCout * 100 + '%'}" v-bind:aria-valuenow="(currentTraitsIndex + 1) / totalTraitCout * 100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <div class="container border rounded mb-4 p-2" style="width: 100%; text-align: justify; font-size: 14px; background-color: white;">
            <b>Pamiętaj!</b> Staraj się wybierać te odpowiedzi, które najbardziej Ciebie charakteryzują - taki jestem najczęściej,
            w&nbsp;większości sytuacji, kontaktów z&nbsp;innymi lub podczas większości zadań, które wykonuję.
        </div>

        <button
            class="btn"
            v-on:click="previousTraits"
            v-if="currentTraitsIndex"
            style="margin-right: 10px; background-color: #c73468; color: white; font-weight: bold;"
        >
            Poprzedni
        </button>

        <button
            class="btn"
            style="background-color: #c73468; color: white; font-weight: bold;"
            v-if="isNextTraitGroup"
            v-on:click="nextTraits"
            :disabled="isCanGoNextTraits"
        >
            Następny
        </button>

        <div class="d-inline" v-else>
            <router-link :to="{name: 'interpersonal'}">
                <button
                    class="btn"
                    style="background-color: #c73468; color: white; font-weight: bold;"
                    v-on:click="nextTraits"
                >
                  Podsumowanie
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Quiz',
    metaInfo: {
        title: 'Quiz'
    },
    data() {
        return {
            traits: [],
            totalScore: [],
            currentTraitsIndex: 0,
            currentDescription: 0,
            totalTraitCout: 0
        }
    },
    created() {
        this.traits = this.$store.getters.getCurrentTraits
        this.totalScore = this.$store.getters.getTotalScore
        this.currentTraitsIndex = this.$store.getters.getCurrentTraitsIndex
        this.currentDescription = this.$store.getters.getCurrentDescriptions
        this.totalTraitCout = this.$store.getters.getTotalTraitsCount
    },
    mounted() {
        this.$store.commit('setCurrentTraitsIndex')
        this.$store.commit('getAllTraitsScoresFromLocalStorage')
        this.$store.commit('getTotalScoresFromLocalStorage')
    },
    computed: {
        isNextTraitGroup: function () {
            return this.$store.getters.isNextTraitGroup
        },
        isCanGoNextTraits: function () {
            const totalPointsSumForTraits = 10
            let traitPointsSum = 0

            this.traits.forEach(trait => {
                traitPointsSum += trait.score
            })

            return totalPointsSumForTraits !== traitPointsSum
        }
    },
    methods: {
        setScore: function (index) {
            this.$store.commit('setScoreToTrait', index);
            this.traits = this.$store.getters.getCurrentTraits
        },
        nextTraits: function () {
            this.$store.commit('incrementCurrentTraitsIndex')
            this.traits = this.$store.getters.getCurrentTraits
            this.currentDescription = this.$store.getters.getCurrentDescriptions
            this.currentTraitsIndex = this.$store.getters.getCurrentTraitsIndex
        },
        previousTraits: function () {
            this.$store.commit('decrementCurrentTraitsIndex')
            this.traits = this.$store.getters.getCurrentTraits
            this.currentDescription = this.$store.getters.getCurrentDescriptions
            this.currentTraitsIndex = this.$store.getters.getCurrentTraitsIndex
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .trait-score {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 35px;
        opacity: 0.4;
    }

    .trait-description {
      font-size: 10px;
      width: 90%;
      padding-bottom: 2px;
    }

    .progress-bar {
        background: #763460;
    }

    b {
        color: #763460;
    }

    .quiz {
      font-weight: bold;
      text-transform: uppercase;
      background-color: #DBBCB8;
      height: 80px;
      font-size: 18px;
      padding-top: 2px;
      border: 1px solid white;
    }

    .btn:focus {
        box-shadow: none;
    }
</style>
